import {createActionCreator, createReducer} from "deox";
import {ControlMapState, LegendConfiguration, MapConfig, MapStore} from "domain/models/map-configuration.model";
import {ControllerOptionsState, initialState as selectedOptionsInitialState} from "domain/models/options.models";
import {LatLng} from "leaflet";
import {Projections, ProjectionsNames} from "domain/core/map-projections";
import {FECEAProjection} from "domain/models/url.models";
import {BaseMap, RecintoType, SimpleMapView} from "domain/models/map.models";
import {DetailedStation, PrimaryTab, Scenario, SummaryStation} from "domain/models/stations.model";

export const initialState: MapStore = {
  config: null,
  selectedOptions: selectedOptionsInitialState,
  commons: {
    mapView: {
      center: new LatLng(40.416729, -3.703339),
      zoom: 6
    },
    opacity: 1,
    referenceProjection: Projections[ProjectionsNames.EPSG_4326],
    pointInfo: null,
    baseMap: BaseMap.Light,
    recinto: RecintoType.region,
    controlMapState: {
      baseMap: false,
      recinto: false
    }
  },
  selectedStation: {
    show: false,
    optionTabs: {
      primaryTab: Scenario.historical
    }
  },

};

const SET_CONFIGURATION = '[Map] :: Set configuration';
const SET_MAP_SELECTED_OPTIONS = '[Map] :: Set map selected options';
const SET_VIEW = '[Map] :: Set map view';
const SET_LEGEND_CONFIGURATION = '[Map] :: Set legend configuration';
const SET_REFERENCE_PROJECTION = '[Map] :: Set reference projection';
const SET_OPACITY = '[Map] :: Set map opacity';
const SET_POINT_INFO = '[Map] :: Set Point Info';
const SET_BASE_MAP = '[Map] :: Set Base Map';
const SET_STATION_STATS = '[Station] :: Set Station Stats';
const SET_DETAILED_STATION_STATS = '[Station] :: Set Detailed Station Stats';
const SET_PRIMARY_TAB = '[Station] :: Set scenario tab for station';
const SET_RECINTO_TYPE = '[Station] :: Set recinto type layer';
const SET_CONTROL_MAP_STATE = '[Station] :: Set state of map controls';

export const setConfiguration = createActionCreator(
    SET_CONFIGURATION,
    (resolve) => (config: MapConfig) => resolve(config)
);
export const setSelectedOptions = createActionCreator(
    SET_MAP_SELECTED_OPTIONS,
    (resolve) =>
        (options: ControllerOptionsState) =>
            resolve({ options })
);

export const setMapView = createActionCreator(
    SET_VIEW,
    (resolve) => (view: SimpleMapView) => resolve(view)
);

export const setLegendConfiguration = createActionCreator(
    SET_LEGEND_CONFIGURATION,
    (resolve) => (legend: LegendConfiguration | undefined) => resolve({ legend })
);

export const setProjections = createActionCreator(
    SET_REFERENCE_PROJECTION,
    (resolve) => (projection: FECEAProjection) => resolve(projection)
);

export const setOpacity = createActionCreator(
    SET_OPACITY,
    (resolve) => (opacity: number) => resolve(opacity)
);

export const setPointInfo = createActionCreator(
    SET_POINT_INFO,
    (resolve) => (featureInfo: LatLng | null) => resolve({featureInfo})
);

export const setBaseMap = createActionCreator(
    SET_BASE_MAP,
    (resolve) => (baseMap: BaseMap) => resolve({baseMap})
);

export const setStationStats = createActionCreator(
    SET_STATION_STATS,
    (resolve) => (station: SummaryStation) => resolve({station})
);

export const setDetailedStationStats = createActionCreator(
    SET_DETAILED_STATION_STATS,
    (resolve) => (detailedStation: DetailedStation) => resolve({detailedStation})
);

export const setPrimaryTab = createActionCreator(
    SET_PRIMARY_TAB,
    (resolve) => (optionTabs: PrimaryTab) => resolve({optionTabs})
);

export const setRecintos = createActionCreator(
    SET_RECINTO_TYPE,
    (resolve) => (recinto: RecintoType) => resolve({recinto})
);

export const setControlMapState = createActionCreator(
    SET_CONTROL_MAP_STATE,
    (resolve) => (controlMapState: ControlMapState) => resolve({controlMapState})
);

export const mapReducer = createReducer(initialState, handle => [
  handle(setConfiguration, (state, { payload }) => ({
    ...state,
    config: {
      ...payload
    }
  })),
  handle(setSelectedOptions, (state, { payload }) => {
    return {
      ...state,
      selectedOptions: payload.options,
    };
  }),
  handle(setProjections, (state, { payload }) => {
    const _mapview = state.commons.mapView;
    const _opacity = state.commons.opacity;
    return {
      ...state,
      commons: {
        ...state.commons,
        referenceProjection: payload,
        mapView: _mapview,
        opacity: _opacity
      },
    };
  }),
  handle(setMapView, (state, { payload }) => {
    return {
      ...state,
      commons: {
        ...state.commons,
        mapView: {
          center: payload.center,
          zoom: payload.zoom
        }
      },
    };
  }),
  handle(setLegendConfiguration, (state, { payload }) => {
    return {
      ...state,
      legendConfiguration: payload.legend,
    };
  }),
  handle(setOpacity, (state, { payload }) => {
    const _mapview = state.commons.mapView;
    const _referenceProjection = state.commons.referenceProjection;
    return {
      ...state,
      commons: {
        ...state.commons,
        referenceProjection: _referenceProjection,
        mapView: _mapview,
        opacity: payload
      },
    };
  }),
  handle(setBaseMap, (state, { payload }) => {
    return {
      ...state,
      commons: {
        ...state.commons,
        baseMap: payload.baseMap
      },
    };
  }),
  handle(setPointInfo, (state, { payload }) => {
    return {
      ...state,
      commons: {
        ...state.commons,
        pointInfo: payload.featureInfo
      },
    }
  }),
  handle(setStationStats, (state, { payload }) => {
    return {
      ...state,
      selectedStation: {
        ...payload.station
      }
    }
  }),
  handle(setDetailedStationStats, (state, { payload }) => {
    return {
      ...state,
      selectedStation: {
        ...state.selectedStation,
        detailedStation: payload.detailedStation
      }
    }
  }),
  handle(setPrimaryTab, (state, { payload }) => {
    return {
      ...state,
      selectedStation: {
        ...state.selectedStation,
        optionTabs: {
          ...state.selectedStation.optionTabs,
          primaryTab: payload.optionTabs
        }
      }
    }
  }),
  handle(setRecintos, (state, { payload }) => {
    return {
      ...state,
      commons: {
        ...state.commons,
        recinto: payload.recinto
      }
    }
  }),
  handle(setControlMapState, (state, { payload }) => {
    return {
      ...state,
      commons: {
        ...state.commons,
        controlMapState: payload.controlMapState
      }
    }
  })
]);