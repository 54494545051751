import { createStore, combineReducers } from 'redux';

import { AppStore } from 'domain/models';

import { rootReducer } from './root';
import { mapReducer } from "./map/map.store";
import { composeWithDevTools } from "redux-devtools-extension";


const appReducer = combineReducers<AppStore>({
  map: mapReducer,
  root: rootReducer
});

export default createStore(appReducer, composeWithDevTools());
