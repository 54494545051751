import i18next from 'i18next';
import i18nextBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(i18nextBackend)
  .use(initReactI18next)
  .init({
    // ns: ['common', 'auth', 'settings'], // Uncomment this to load all Namespaces on init
    nsSeparator: ':',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: 'en',
    lng: 'en',
    debug: process.env.NODE_ENV === 'development',
    cleanCode: true,
    load: 'languageOnly',
    partialBundledLanguages: true,
    react: {
      useSuspense: true,
    },
  });

export { i18next };
export interface LangInterface {
  label: string;
  value: string;
  matchers: string[]
}

export const Langs: LangInterface[] = [
  { label: 'ES', value: 'es', matchers: ['es', 'es-es'] },
  { label: 'EN', value: 'en', matchers: ['en', 'en-en', 'en-us'] },
];
