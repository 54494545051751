import React, { Suspense } from 'react';

import { Route, Navigate, Routes } from 'react-router-dom';

import { RoutesURL } from '../domain/core';

const RootApp = React.lazy(
  () => import(/* webpackChunkName: "app.root" */ 'pages/app/RootApp')
);
const Landing = React.lazy(
  () => import(/* webpackChunkName: "home.landing" */ 'pages/home/Landing')
);

export const Router = React.memo(() => {
  return (
    <Suspense fallback={<h1>Cargando...</h1>}>
      <Routes>
        <Route path={RoutesURL.Landing}
               element={<Landing />} />
        <Route path={RoutesURL.AppRoot}
               element={<RootApp />} />
        <Route path={RoutesURL.All}
               element={<Navigate to={RoutesURL.AppRoot} />} />
        <Route path={RoutesURL.Contact}
               element={<RootApp />} />
      </Routes>
    </Suspense>
  );
});
