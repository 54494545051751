import { LatLng } from "leaflet";
import { Domain } from "../models/map-configuration.model";
import { SimpleMapView } from "../models/map.models";
import { FECEAUrlMapParams, FECEAUrlParams } from "../models/url.models";

export const Api = {
  baseUrl:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080'
      : window.location.origin,
  arcasTool: 'https://arcas-tool.predictia.es/arcas-tool',
  contact: '/newsletter/subscribe',
  auth: {
    login: '/auth/signin',
    renew: '/auth/renew',
    register: '/register',
    account: '/account'
  },
  config: '/data/config',
  stationSummary: '/stations/summary',
  stationDetails: '/stations/details',
  listMunicipios: '/municipiosNames',
  allMunicipios: '/regions',
  getCoordMunicipio: '/coord',
  attributionMap: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  export: {
    puntoMet: '/export/puntoMet',
    csv: '/export/csv',
    excel: '/export/excel',
    tmy: '/export/tmy'
  }
};

export const Variable = {
  DD: 'dd',
  VV: 'vv',
  sfcWind: "sfcwind",
}

export const StationFiles = {
  cantabrian: 'station_metadata.json',
  french: 'mun_stations_FR.json',
  portuguese: 'mun_stations_PT.json'
}

export const BorderFiles = {
  cantabrian: 'recintos_simple.json',
  french: 'cantons-nouvelle-aquitaine.geojson',
  portuguese: 'portugal-norte_borders.geojson'
}

export const WmsParams = {
  getMap: "request=getMap",
  getLegend: "request=GetLegendGraphic",
  getRange: "request=GetRange",
  getFeatInfo: "request=GetFeatureInfo",
  getCoverage: "request=getCoverage",
  image: "format=image/png",
  xml: "format=xml",
  serviceWMS: "service=WMS",
  serviceWCS: "service=WCS",
  transparent: "transparent=true",
  version: "version=1.1.1"
}
export const WmsUrlFormat = {
  image: "&format=image/png",
  xml: "&format=xml",
  json: "&format=application/json",
  infoformatJson: "info_format=application/json",
  windy: "format=aaigrid",
  style: "dynamic/nearest"
}

export const paramsMapLanding = new Map<Domain, FECEAUrlMapParams>([
  ["nouvelle-aquitaine", {
    domain: "nouvelle-aquitaine",
    model: "CAMS",
    dataset: "Climate",
    period: "reference",
    scenario: "historical",
    season: "year",
    variable: "pm25a25",
    baseline: "p1",
    magnitude: 'VALUE',
  }],
  ["portugal-norte", {
    domain: "portugal-norte",
    model: "CAMS",
    dataset: "Climate",
    period: "reference",
    scenario: "historical",
    season: "year",
    variable: "pm25a25",
    baseline: "p1",
    magnitude: 'VALUE',
  }],
  ["cantabrian", {
    domain: "cantabrian",
    model: "ACAO",
    dataset: "Climate",
    period: "reference",
    scenario: "historical",
    season: "year",
    variable: "tmin",
    baseline: "p1",
    magnitude: 'VALUE',
  }]
]);

export const paramsMapCenter = new Map<Domain, SimpleMapView>([
  ["nouvelle-aquitaine", {center: new LatLng(44.67646564865964, -0.8843994140625001), zoom: 7}],
  ["portugal-norte", {center: new LatLng(41.286062387498276, -7.775573730468751), zoom: 8}],
  ["cantabrian", {center: new LatLng(42.819580715795915, -5.289916992187501), zoom: 8}],
])

export const stagingConfig: string = window.btoa(
    JSON.stringify({
      primary: {
        domain: "cantabrian",
        model: "ACAO",
        period: "reference",
        scenario: "historical",
        season: "year",
        variable: "tmin",
        baseline: "p1",
        magnitude: 'VALUE',
      },
      commons: {
        zoom: 8,
        proj: "EPSG:3857",
        lat: 42.9,
        lng: -4.95
      }
    } as FECEAUrlParams));

export const DefaultHash: string = ((env: string | undefined) => {
  console.log(`Running on environment: ${env}`);
  switch (env) {
    case 'dev':
      return '';
    case 'staging':
      return stagingConfig;
    default:
      return stagingConfig;
  }
})(process.env.REACT_APP_ATLAS_ENV);