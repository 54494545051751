import { createReducer } from 'deox';

import * as Actions from './root.actions';

import { RootStore, Notification } from 'domain/models';

export const initialState: RootStore = {
  notifications: []
};

export const rootReducer = createReducer(initialState, handle => [
  handle(Actions.pushNotification, (state, { payload }) => ({
    notifications: state.notifications.concat(
      Object.freeze({ ...payload, innerKey: Date.now() })
    )
  })),
  handle(Actions.removeNotification, (state, { payload }) => ({
    notifications: state.notifications.filter(
      (n: Notification) => {
        return n.innerKey !== payload
      }
    )
  }))
]);
