import { DeepNullable } from "./shared.types";
import {
  Dataset,
  Domain,
  MapConfigBaseline,
  MapConfigModel,
  MapConfigPeriod,
  MapConfigScenario,
  MapConfigurationTempFilter,
  MapConfigVariables, ValueType
} from "./map-configuration.model";


export type NullableControllerOptionsState = DeepNullable<ControllerOptionsState>;

export interface ControllerOptionsState {
  domain: Domain;
  dataset: Dataset;
  models: MapConfigModel;
  variable: MapConfigVariables;
  scenario: MapConfigScenario;
  baseline: MapConfigBaseline;
  period: MapConfigPeriod;
  season: MapConfigurationTempFilter;
  magnitude: ValueType;
}

export const initialState: NullableControllerOptionsState = {
  domain: null,
  dataset: null,
  models: null,
  variable: null,
  scenario: null,
  baseline: null,
  period: null,
  season: null,
  magnitude: null,
};

export interface ControllerOptionsData {
  domains: Domain[];
  datasets: Dataset[];
  models: MapConfigModel[];
  variables?: MapConfigVariables[];
  scenarios: MapConfigScenario[];
  temporalFilters: MapConfigurationTempFilter[];
  periods: MapConfigPeriod[];
  magnitudes: ValueType[];
  baselines: MapConfigBaseline[];
}

export const initialData: ControllerOptionsData = {
  domains: [],
  datasets: [],
  models: [],
  variables: [],
  scenarios: [],
  temporalFilters: [],
  periods: [],
  magnitudes: [],
  baselines: []
};

export interface SectionsDiff {
  dataset: boolean;
  variable: boolean;
  period: boolean;
  season: boolean;
}