import {FECEAProjections} from "domain/models/url.models";

export const ProjectionsNames: { [K: string]: keyof FECEAProjections } = {
  EPSG_3857: 'EPSG:3857'
}

export const Projections: FECEAProjections = {
  'EPSG:3857': {
    code: 'EPSG:3857',
    epsg: 'EPSG:3857',
    zoom: 6,
    center_lat: 40.416729,
    center_long: -3.703339,
    label: 'Robinson',
  }
}