import {LatLng} from "leaflet";

export enum BaseMap {
  Light = 'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png',
  Dark = 'https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png',
  Street = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png',
  PNOA = 'https://www.ign.es/wms-inspire/pnoa-ma',
  France = 'https://wxs.ign.fr/essentiels/geoportail/wmts?service=WMTS&request=GetTile&version=1.0.0&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&layer=ORTHOIMAGERY.ORTHOPHOTOS&format=image/jpeg&style=normal',
  Portugal = 'https://map7.infoportugal.info/wmts/aerial/ip_grid/{z}/{x}/{y}.png',
  Labels = 'https://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}{r}.png',
  Canvas = 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
}

export const baseMapLabel = (baseMap: BaseMap) => {
  switch (baseMap) {
    case BaseMap.Light:
      return 'Claro';
    case BaseMap.Dark:
      return 'Oscuro';
    case BaseMap.Street:
      return 'Callejero';
    case BaseMap.PNOA:
      return 'PNOA';
    case BaseMap.France:
      return 'Satélite';
    case BaseMap.Portugal:
      return 'Satélite';
    case BaseMap.Labels:
      return 'Etiquetas';
  }
};

export enum RecintoType {
  region = 'region',
  municipio = 'municipio',
}

export interface Station {
  name: string;
  x: number;
  y: number;
}

export interface RecintosCollections {
  features: Recinto[];
  type: string;
}

export interface MunicipiosCollections {
  features: Municipio[];
  type: string;
}

export interface Recinto {
  geometry: RecintoGeometry;
  properties: RecintoProperties;
  type: string;
}

export interface RecintoGeometry {
  coordinates: any[][][];
  type: string;
}

export interface RecintoProperties {
  altitud: string;
  id: number;
  indicativo: string;
  latitud: string;
  longitud: string;
  nombre: string;
  nom_municipio: string;
  nom_prov: string;
  x: number;
  y: number;
}

export interface Municipio {
  geometry: RecintoGeometry;
  properties: MunicipioProperties;
  type: string;
}

export interface MunicipioProperties {
  acom_name: string;
  mun_code: string;
  mun_area_code: string
  year: string
  geo_point_2d: number[]
  mun_type: string
  prov_code: string
  acom_code: string
  mun_name: string
  prov_name: string
}

export interface SimpleMapView {
  center: LatLng;
  zoom: number;
}