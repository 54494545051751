export type PrimaryTab = 'historical' | 'rcp85' | 'rcp45';
export enum Periods {
  near_future='near_future',
  medium_future='medium_future',
  far_future='far_future',
}
export type RcpTypes = 'rcp45' | 'rcp85';

export enum Scenario {
  historical = 'historical',
}


export interface SummaryStation {
  code?: string;
  name?: string;
  show: boolean;
  summary?: ScenariosStation;
  detailedStation?: DetailedStation;
  optionTabs: OptionTabs;
}

export type ScenariosStation = {
  [key in RcpTypes]: Rcp;
} & {
  historical: Historical;
}

export type Rcp = {
  [key in RcpTypes]: RcpData;
} & {
  historical: RcpHistorical;
};

export type Historical = {
  [K in StationVariable]: Statics
};

export type RcpData = {
 [K in StationVariable]: StaticsPeriod
}

export interface OptionTabs {
  primaryTab: PrimaryTab
}

export type RcpHistorical = {
  [K in StationVariable]: { reference: Statics };
}

export interface Statics {
  "mean": number,
  "mediana": number,
  "max": number,
  "min": number,
  "std": number,
  "prc05": number,
  "prc25": number,
  "prc75": number,
  "prc95": number,
  "units": string,
}

export interface StaticsPeriod {
  far_future: Statics,
  medium_future: Statics,
  near_future: Statics,
}

export type StationVariable = 'TSECA' | 'TEFECCIELO' | 'IRRADDIRECTA' | 'IRRADDIFUSA' | 'HESPECIFICA' | 'HR'|
    'VV' | 'DDVV' | 'U_COMPONENT_VV' | 'V_COMPONENT_VV' | "tasmin" | "pm25";

export interface DetailedStation {
  [key: string]: DetailedScenarioRaw;
}

export interface DetailedScenarioRaw {
  historical: DetailedScenarioRawHist

}

export interface DetailedScenarioRawHist {
  dates: { [V in string]: string },
  values: Array<number>
}

export interface DetailedScenario {
  season: string,
  dates: Array<string>,
  values: Array<number>
}